<template>
  <section>
    <Filters @change="updateFilters" withPeriod />

    <div v-if="loading" class="pt-2">
      <b-skeleton v-for="index in 5" :key="index" 
        width="100%" type="input" class="mb-2"
      ></b-skeleton>
    </div>
    <noFilterResults v-else-if="!loading && !surgeries.length"/>
    
    <div v-else-if="!loading">
      <PatientTable :surgeries="surgeries" readonly />
      <div class="pagination-position">
        <b-pagination
          v-model="page"
          :total-rows="count"
          :per-page="limit"
          first-number
          last-number
          size="sm"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'FinishedSurgeries',

  components: {
    Filters: () => import('@/components/SurgeryCenter/Surgeries/FinishedSurgeriesFilter.vue'),
    PatientTable: () => import('@/components/SurgeryCenter/Surgeries/SurgeriesTable.vue'),
    noFilterResults: () => import('@/components/General/noFilterResults.vue'),
  },

  data () {
    return {
      surgeries: [],

      clinic: getCurrentClinic(),
      filters: {
        status: ['Finalizado'],
      },

      page: 1,
      count: 0,
      limit: 0,

      loading: false,
    }
  },

  created () {
    this.getDailySurgeries()
  },

  methods: {
    getCurrentClinic,
    async updateFilters (data) {
      this.filters = { 
        ...data,
        status: ['Finalizado'],
      }
      this.page = 1
      await this.getDailySurgeries()
    },
    async getDailySurgeries () {
      this.loading = true
      try {
      const res  = await this.api.getDailySurgeries(this.clinic.id, JSON.stringify(this.filters), this.page)
      this.surgeries = res.data.data
      this.count = res.data.total
      this.limit = res.data.per_page

      } catch (error) {
        this.$toast.error('Não foi possível carregar as cirurgias do dia, contate o suporte se o erro persistir.')
      } finally {
        this.loading = false
      }
    },
  },

  watch: {
    page() { this.getDailySurgeries() },
  }
}
</script>

<style lang="scss">
</style>